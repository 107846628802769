var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NumStatistics", on: { click: _vm.jumpDetail } },
    [
      _c("ul", { staticClass: "num-box flex-just-between" }, [
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.lateCount || 0))]),
          _c("span", [_vm._v("迟到")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.earlyOutCount || 0))]),
          _c("span", [_vm._v("早退")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.missCount || 0))]),
          _c("span", [_vm._v("缺卡")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.absentCount || 0))]),
          _c("span", [_vm._v("旷工")]),
        ]),
      ]),
      _c("ul", { staticClass: "num-box flex-just-between" }, [
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.outsideCount || 0))]),
          _c("span", [_vm._v("外出")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.travelCount || 0))]),
          _c("span", [_vm._v("出差")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.vacationCount || 0))]),
          _c("span", [_vm._v("请假")]),
        ]),
        _c("li", [
          _c("span", [_vm._v(_vm._s(_vm.numObj.overtimeCount || 0))]),
          _c("span", [_vm._v("加班")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }